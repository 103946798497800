<template>
	<div class="teamMembers_block">
		<section class="header-bg" style="min-height: 255px;">
		</section>
		<div style="width:71%;height: 20px;margin:0 auto;margin-top: 40px;">
			<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">TEAM MEMBERS
				</strong></p>
		</div>

		<!-- 团队 -->

		<div class="teamM">
			<div v-for="(item, index) in teamArr" :key="index" class="row">
				<div class="card">
					<img v-if="item.img" :src="item.img" :alt="item.authorName">
					
					<div>
						<h3>{{ item.authorName }}</h3>
						<p>{{ item.affiliation ? item.affiliation.replace(/,\s*/g, ', ') : 'No Data.' }}</p>
						<p style="color: #000;">{{ item.position || 'No Data.'}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	export default {
		name: "teamMembers",
		data() {
			return {
				teamArr: [{
						authorName: 'PROFESSOR DR.NOORHIDAWATI BINTI ABDULLAH',
						affiliation: 'Department of Library and Information Science,Faculty of Arts and Social Science,Universiti Malaya,Kuala Lumpur',
						img: require('@/assets/images/team/NOORHIDAWATI BINTI ABDULLAH.png'), // 使用 require 进行引入
						profile: '',
						position: 'Researcher'
					},
					{
						authorName: 'ASSOCIATE PROFESSOR DR. YANTI IDAYA ASPURA BINTI MOHD KHALID',
						affiliation: 'Department of Library and Information Science,Faculty of Arts and Social Sciences,University of Malaya,Kuala Lumpur,Malaysia',
						img: require('@/assets/images/team/YANTI IDAYA ASPURA BINTI MOHD KHALID.png'), // 同样使用 require
						profile: '',
						position: 'Researcher'
					},
					{
						authorName: 'PROFESSOR MUHAMMAD AQEEL ASHRAF',
						affiliation: 'Zebiline International Publishing,CBD Perdana 3,Persiaran,Lingkaran Cyber Point Timur,Cyber 12,63000 Cyberjaya,Selangor,Malaysia',
						img: require('@/assets/images/team/MUHAMMAD AQEEL ASHRAF.png'), // 同样处理
						profile: '',
						position: 'Senior Consultant'
					},
					{
						authorName: 'DR.AMIN D. MALAYERI',
						affiliation: 'Doctor of Business Administration,Post-master of Business Administration in Data Science,MBA in Information Technology Management',
						img: require('@/assets/images/team/DR.AMIN D. MALAYERI.png'),
						profile: '',
						position: 'Senior Consultant'
					},
				]
			};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {

		},
	}
</script>

<style scoped lang="scss">
	.teamMembers_block {
		width: 100%;
		min-width: 1200px;
	}

	.teamM {
		margin-top: 80px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 20px;

	}

	.row {
		width: 35%;
	}

	.card {
		width: 90%;
		height: 75%;
		display: flex;
		padding: 20px;
		background-color: #edfeff;
		border-radius: 10px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	}

	.card img {
		width: 22%;
		min-height: inherit;
		margin-right: 20px;
	}

	.card h3 {
		margin-top: 10px;
		font-size: 18px;
	}

	.card p {
		line-height: 1.6;
		margin-top: 10px;
		font-size: 14px;
		color: #666;
	}
</style>